
import OpacityTransition from "@/components/transitions/OpacityTransition.vue";
import { computed, ComputedRef, defineComponent } from "vue";
import { ModalBackdropStyleInterface } from "./modal_interfaces";
import { modalBackdropDefaultStyle } from "./modal_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
  name: "ModalBackdrop",
  emits: ["close"],
  components: { OpacityTransition },
  props: {
    /**
     * style of the backdrop
     */
    backdropStyle: {
      type: Object as () => ModalBackdropStyleInterface,
      default: {} as ModalBackdropStyleInterface,
    },
    /**
     * Duration in Milliseconds for transition
     */
    transitionDuration: {
      type: Number,
      default: 150,
    },
    /**
     * bool to show the backdrop
     */
    isBackdropVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const finalModalBackdropStyle: ComputedRef<ModalBackdropStyleInterface> =
      computed(() => {
        return {
          ...modalBackdropDefaultStyle,
          ...getSpaceConfigStyle()?.modalBackdrop,
          ...props.backdropStyle,
        };
      });

    return {
      finalModalBackdropStyle,
    };
  },
});
