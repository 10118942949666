import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_opacity_transition = _resolveComponent("opacity-transition")!

  return (_openBlock(), _createBlock(_component_opacity_transition, { transitionDuration: _ctx.transitionDuration }, {
    default: _withCtx(() => [
      (_ctx.isBackdropVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["w-screen h-screen", `bg-${_ctx.finalModalBackdropStyle.backDropBackgroundColor} bg-opacity-${_ctx.finalModalBackdropStyle.backDropBgOpacity}`]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["transitionDuration"]))
}