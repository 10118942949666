
import ModalContentTransition from "@/components/transitions/ModalContentTransition.vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import ModalBackdrop from "./ModalBackdrop.vue";
import { ModalBackdropStyleInterface } from "./modal_interfaces";

export default defineComponent({
    components: { ModalBackdrop, ModalContentTransition },
    name: "ModalContainer",
    emits: ["close"],
    props: {
        modalBackdropStyle: {
            type: Object as () => ModalBackdropStyleInterface,
            default: {} as ModalBackdropStyleInterface,
        },
        /**
         * Duration in Milliseconds for transition
         */
        transitionDuration: {
            type: Number,
            default: 150,
        },
        /**
         * if it is false the modal is getting displayed on the top of the mainContainer
         */
        isCentered: {
            type: Boolean,
            default: true,
        },

        /**
         * Main Container id to resize depending on the container
         */
        mainContainerId: {
            type: String,
            default: "main-container",
        },
    },
    setup(props, ctx) {
        const isModalVisible = ref<boolean>(true);
        /**
         * This functionis closing the modal.
         * The timeout is necessary to create a leaving transition
         *
         * @returns void
         */
        function closeModal(): void {
            isModalVisible.value = false;
            setTimeout(() => {
                ctx.emit("close");
            }, props.transitionDuration);
        }

        const modalWrapperElement = ref<HTMLElement>();

        /**
         * calculates the position of the wrapper Container depending on
         * the size of the main Container
         *
         * @returns void
         */
        function calculateModalPosition(): void {
            const mainContainer = document.getElementById(
                props.mainContainerId
            );

            if (mainContainer && modalWrapperElement.value) {
                const mainContainerRect = mainContainer.getBoundingClientRect();

                modalWrapperElement.value.style.width = `${mainContainerRect.width}px`;
                modalWrapperElement.value.style.height = `${mainContainerRect.height}px`;
                modalWrapperElement.value.style.left = `${mainContainerRect.left}px`;
                modalWrapperElement.value.style.top = `${mainContainerRect.top}px`;
            } else if (modalWrapperElement.value) {
                modalWrapperElement.value.style.width = "100%";
                modalWrapperElement.value.style.height = "100%";
                modalWrapperElement.value.style.left = "0";
                modalWrapperElement.value.style.top = "0";
            }
        }
        onMounted(() => {
            calculateModalPosition();
            window.addEventListener("resize", calculateModalPosition, false);
        });
        onUnmounted(() => {
            window.removeEventListener("resize", calculateModalPosition);
        });

        return {
            closeModal,
            isModalVisible,

            modalWrapperElement,
        };
    },
});
