import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-6 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_container = _resolveComponent("modal-container")!

  return (_openBlock(), _createBlock(_component_modal_container, {
    transitionDuration: _ctx.transitionDuration,
    modalBackdropStyle: _ctx.modalStyle,
    isCentered: _ctx.isCentered,
    mainContainerId: _ctx.mainContainerId,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    ref: "modalContainerElement"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["pointer-events-auto w-120 max-w-full", [
        _ctx.isCentered ? 'mb-24' : '',
        `bg-${_ctx.finalMiniModalStyle.backgroundColor} shadow-${_ctx.finalMiniModalStyle.shadow} rounded-${_ctx.finalMiniModalStyle.roundedSize}`,
      ]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ], 2)
    ]),
    _: 3
  }, 8, ["transitionDuration", "modalBackdropStyle", "isCentered", "mainContainerId"]))
}