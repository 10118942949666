
import { defineComponent } from "vue";

export default defineComponent({
  name: "OpacityTransition",
  props: {
    /**
     * Duration in Milliseconds for transition
     */
    transitionDuration: {
      type: Number,
      default: 150,
    },
  },
});
