import {
    MiniModalStyleInterface,
    ModalBackdropStyleInterface,
    ModalStyleInterface,
} from "./modal_interfaces";

export const modalBackdropDefaultStyle: ModalBackdropStyleInterface = {
    backDropBackgroundColor: "black",
    backDropBgOpacity: 60,
};

export const miniModalDefaultStyle: MiniModalStyleInterface = {
    backgroundColor: "white",
    shadow: "none",
    roundedSize: "md",
};
export const centeredModalDefaultStyle: ModalStyleInterface = {
    ...miniModalDefaultStyle,
    headerBackgroundColor: "white-dark",
    spacerColor: "grey-lighter",
    titleColor: "black",
    titleFontWeight: "medium",
    titleSize: "md",
    titleWidth: 50,
};
