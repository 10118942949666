
import { computed, ComputedRef, defineComponent, ref } from "vue";
import ModalContainer from "./ModalContainer.vue";
import { MiniModalStyleInterface } from "./modal_interfaces";
import { miniModalDefaultStyle } from "./modal_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
  components: { ModalContainer },
  name: "MiniModal",
  emits: ["close"],
  props: {
    /**
     * style of the modal
     */
    modalStyle: {
      type: Object as () => MiniModalStyleInterface,
      default: {} as MiniModalStyleInterface,
    },
    /**
     * Duration in Milliseconds for transition
     */
    transitionDuration: {
      type: Number,
      default: 150,
    },
    /**
     * Main Container id to resize depending on the container
     */
    mainContainerId: {
      type: String,
      default: "main-container",
    },
    /**
     * if it is false the modal is getting displayed on the top of the mainContainer
     */
    isCentered: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const finalMiniModalStyle: ComputedRef<MiniModalStyleInterface> = computed(
      () => {
        return {
          ...miniModalDefaultStyle,
          ...getSpaceConfigStyle()?.miniModal,
          ...props.modalStyle,
        };
      }
    );
    const modalContainerElement = ref<InstanceType<typeof ModalContainer>>();
    /**
     * this close modal function is triggering the closeModal function from
     * the modal container to create a transition
     *
     * @returns void
     */
    function closeModal(): void {
      modalContainerElement.value?.closeModal();
    }
    return {
      finalMiniModalStyle,
      modalContainerElement,
      closeModal,
    };
  },
});
