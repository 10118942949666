import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-screen h-screen fixed top-0 left-0 z-50" }
const _hoisted_2 = {
  key: 0,
  class: "h-full w-full absolute pointer-events-none left-0 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_backdrop = _resolveComponent("modal-backdrop")!
  const _component_modal_content_transition = _resolveComponent("modal-content-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal_backdrop, {
      transitionDuration: _ctx.transitionDuration,
      backdropStyle: _ctx.modalBackdropStyle,
      isBackdropVisible: _ctx.isModalVisible,
      onClose: _ctx.closeModal
    }, null, 8, ["transitionDuration", "backdropStyle", "isBackdropVisible", "onClose"]),
    _createVNode(_component_modal_content_transition, { transitionDuration: _ctx.transitionDuration }, {
      default: _withCtx(() => [
        (_ctx.isModalVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                ref: "modalWrapperElement",
                class: _normalizeClass(["absolute py-4 sm:py-20 lg:py-26 px-3 sm:px-8 flex flex-row justify-center", _ctx.isCentered ? 'items-center' : 'items-start'])
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["transitionDuration"])
  ]))
}